import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { LOGO_IMG } from '../../../constants';

import './Logo.scss';

function Logo({ onClick }) {
  const onClickHandler = useCallback(e => {
    onClick();
  }, [onClick]);

  return (
    <div className="logo">
      <Link to="/#home">
        <img
          onClick={onClickHandler}
          src={LOGO_IMG.src}
          alt={LOGO_IMG.alt}
        />
      </Link>
    </div>
  );
}

export default Logo;
