import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import scrollToHash from '../Services/ScrollToService'

export default function useScrollToHash() {
  const location = useLocation();

  useEffect(() => {
    scrollToHash(location.hash);
  }, [location]);

  return null;
};
