import './SocialIcon.scss';

function SocialIcon({ link, src, alt, title }) {
  return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer">

        <img
          src={src}
          alt={alt}
          title={title}
        />
      </a>
  );
}

export default SocialIcon;
