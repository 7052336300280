import { useCallback, useState } from 'react';

import MenuIcon from '../../Components/MenuIcon';
import Menu from './Menu';
import Logo from './Logo';
import Banner from './Banner';

import './Header.scss';

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuItems = [
    {
      title: 'Про Мене',
      link: '/#about',
    },
    {
      title: 'Портфоліо',
      link: '/#portfolio',
    },
    {
      title: 'Прайс',
      link: '/#price',
    },
    {
      title: 'Контакти',
      link: '/#contacts',
    },
  ];

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  return (
    <div className="header">
      <div className="menu-icon">
        <MenuIcon
          isOpen={isMenuOpen}
          onClick={setMenuOpen}
        />
      </div>

      <Logo onClick={closeMenu} />

      <Menu
        items={menuItems}
        isOpen={isMenuOpen}
        onMenuItemSelect={closeMenu}
      />

      <Banner />
    </div>
  );
}

export default Header; 
