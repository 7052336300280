import { useRef } from 'react';
import { useLoaderData } from 'react-router-dom';

import SocialIcon from '../../../Components/SocialIcon';
import useIsVisible from '../../../Hooks/useIsVisible';
import { CONTACTS_BG_IMG_SRC } from '../../../constants';

import './Contacts.scss';

function Contacts() {
  const { socials, contacts } = useLoaderData();
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  return (
    <div
      className="contacts"
      style={{ backgroundImage: `url(${CONTACTS_BG_IMG_SRC})` }}
    >
      <div
        className={`contacts-inner ${ isVisible ? 'animated' : '' }`}
        ref={ref}
      >
        <div className="phone">
          {contacts.phone}
        </div>

        <div className="socials">
          {
            socials.map((item, index) => (
              <div
                key={index}
                className="social-icon"
              >
                <SocialIcon
                  link={item.link}
                  src={item.src}
                  alt={item.alt}
                  title={item.title}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Contacts;
