import AlbumCover from './AlbumCover';

import './Portfolio.scss';

function Portfolio({ albums }) {
  return (
    <div className="portfolio">
      {
        albums.map((album, index) => (
          <AlbumCover
            key={index}
            index={index}
            album={album}
          />
        ))
      }
    </div>
  );
}

export default Portfolio;
