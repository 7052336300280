import ToggleSwitch from '../../../../Components/ToggleSwitch';

import './PriceItem.scss';

function PriceItem({ title, price, selected, onItemSelectionChange }) {
  const onChangeHandler = (checked) => {
    onItemSelectionChange(checked, price)
  };

  return (
    <div className="price-item">
      <ToggleSwitch
        selected={selected}
        onChange={onChangeHandler}
      />

      <div className="title">
        {title}
      </div>
    </div>
  );
}

export default PriceItem;
