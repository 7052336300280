import { useCallback } from 'react';

import './MenuIcon.scss';

function MenuIcon({ isOpen, onClick }) {
  const onClickHandler = useCallback(e => {
    onClick(!isOpen);
  }, [onClick, isOpen]);

  return (
    <div
      className={`menu-btn ${isOpen ? 'open' : ''}`}
      onClick={onClickHandler}
    >
      <div className="menu-btn__burger"></div>
    </div>
  );
}

export default MenuIcon;
