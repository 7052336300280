import './Banner.scss';

function Banner() {
  return (
    <div className="banner">
      <div className="banner-inner">
        <div className="banner-part top">
          <h1>СЛАВА УКРАЇНІ!</h1>
        </div>

        <div className="banner-part bottom">
          <h1>ГЕРОЯМ СЛАВА!</h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
