import AlbumImage from './AlbumImage';
import AlbumDescription from './AlbumDescription';

import './AlbumCover.scss';

function AlbumCover({ index, album }) {
  return (
    <div className={`album-cover ${ index % 2 ? 'reversed' : '' }`}>
      <AlbumImage
        coverImgSrc={album.coverImgSrc}
        url={album.url}
      />

      <AlbumDescription
        title={album.title}
        description={album.description}
        url={album.url}
      />

      <div className={`cover-abstract ${ index % 2 ? 'reversed' : '' }`}>
        <div className="abstract-figure one"></div>
        <div className="abstract-figure two"></div>
        <div className="abstract-figure three"></div>
      </div>
    </div>
  );
}

export default AlbumCover;
