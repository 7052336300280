const prices = [
  {
    title: 'Макіяж',
    price: 1300,
    selected: true,
  },
  {
    title: 'Зачіска',
    price: 1000,
    selected: false,
  },
  {
    title: 'Виїзд майстра по місту Києву',
    price: 700,
    selected: false,
  },
];

export default prices;
