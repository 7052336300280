import { useRef } from 'react';
import { Link } from 'react-router-dom';

import useIsVisible from '../../../../../Hooks/useIsVisible';

import './AlbumDescription.scss';

function AlbumDescription({ title, description, url }) {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  return (
    <div className="cover-description">
      <div className="description-container">
        <div className="description-bg">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>

        <div
          ref={ref}
          className={ `description ${ isVisible ? 'visible' : '' }`}
        >
          <h2>
            <Link to={`/albums/${url}`}>
              {title}
            </Link>
          </h2>

          <p>
            <Link to={`/albums/${url}`}>
              {description}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AlbumDescription;
