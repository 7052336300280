import { useCallback } from 'react';

import './ToggleSwitch.scss';

function ToggleSwitch({ selected, onChange }) {
  const onChangeHandler = useCallback((e) => {
    onChange(e.target.checked)
  }, [onChange]);

  return (
    <div className="toggle-switch">
      <label className="switch">
        <input
          type="checkbox"
          onChange={onChangeHandler}
          defaultChecked={selected}
          name="toggle"
        />

        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
