export const LOGO_IMG = {
  src: '/static/img/logo.png',
  alt: 'MakeUp Magic',
};

export const HOME_BG_VIDEO_SRC = '/static/video/cover-bg.mp4?v=1.0';

export const PRESENTATION_VIDEO = {
  src: '/static/video/about.mp4?v=1.0',
  link: 'https://www.youtube.com/watch?v=EMXEuM3HWbI',
};

export const ALBUM_COVER_BG_IMG = {
  src: '/static/img/album-cover-bg.png',
  alt: 'MakeUp Magic',
};

export const CONTACTS_BG_IMG_SRC = '/static/img/contacts-bg.png';
