import { Link } from 'react-router-dom';

import { ALBUM_COVER_BG_IMG } from '../../../../../constants';

import './AlbumImage.scss';

function AlbumImage({ coverImgSrc, url }) {
  return (
    <div className="cover-image">
      <div
        className="image-container"
        style={{
          backgroundImage: `url(${coverImgSrc})`
        }}>

        <img
          className="album-cover-bg"
          src={ALBUM_COVER_BG_IMG.src}
          alt={ALBUM_COVER_BG_IMG.alt}
        />

        <div className="image-blink"></div>

        <Link to={`/albums/${url}`} >
          <div className="album-front-bg" />
        </Link>
      </div>
    </div>
  );
}

export default AlbumImage;
