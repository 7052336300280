function getInitialPrice(prices) {
  return prices.reduce((acc, item) => {
    if (item.selected) {
      acc += item.price;
    }

    return acc;
  }, 0);
}

export default getInitialPrice;
