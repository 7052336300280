import { useRouteError } from 'react-router-dom';

import Header from '../Header';

import './ErrorPage.scss';

function ErrorPage() {
  const error = useRouteError();

  return (
    <div>
      <Header />

      <div className="error-page">
        <h1>{error.status} {error.statusText || error.message}</h1>
      </div>
    </div>
  );
}

export default ErrorPage;
