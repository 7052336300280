const socials = [
  {
    link: 'https://instagram.com/allamakovetska_makeup',
    src: '/static/img/social-icons/instagram.png',
    alt: 'MakeUp Magic Instagram',
    title: '',
  },
  {
    link: 'https://youtube.com/@MakeUpMagia',
    src: '/static/img/social-icons/youtube.png',
    alt: 'MakeUp Magic YouTube',
    title: '',
  },
  {
    link: 'https://t.me/380677369224',
    src: '/static/img/social-icons/telegram.png',
    alt: 'MakeUp Magic Telegram',
    title: '',
  },
];

export default socials;
