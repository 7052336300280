import { useLoaderData } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';

import 'yet-another-react-lightbox/styles.css';
import './Album.scss';

function Album() {
  const album = useLoaderData();

  return (
    <div className="album">
      <Lightbox
        plugins={[Inline]}
        slides={album.sources}
      />
    </div>
  );
}

export default Album;
