import { useCallback, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import PriceItem from './PriceItem';
import getInitialPrice from './getInitialPrice';
import scrollToHash from '../../../Services/ScrollToService';

import './Price.scss';

function Price() {
  const { prices } = useLoaderData();
  const [finalPrice, setFinalPrice] = useState(getInitialPrice(prices));

  const onItemSelectionChange = useCallback((selected, price) => {
    setFinalPrice(
      selected
        ? (finalPrice + price)
        : (finalPrice - price)
    );
  }, [finalPrice, setFinalPrice]);

  const order = () => {
    scrollToHash('#contacts', 'smooth');
  };

  return (
    <div className="price">
      <div className="abstract-container"></div>

      <div className="price-container">
        <div className="final-price">
            <div>{finalPrice}</div>
            <span>грн</span>
        </div>

        <div className="select-service-container">
          {
            prices.map((item, index) => (
              <PriceItem
                key={index}
                title={item.title}
                price={item.price}
                selected={item.selected}
                onItemSelectionChange={onItemSelectionChange}
              />
            ))
          }
        </div>

        <div className="order-btn">
          <button onClick={order}>
            Замовити
          </button>
        </div>
      </div>
    </div>
  );
}

export default Price;
