import MenuItem from './MenuItem';

import './Menu.scss';

function Menu({ items, isOpen, onMenuItemSelect }) {
  return (
    <ul className={`menu ${isOpen ? 'open' : ''}`}>
      {
        items.map((item, index) => (
          <MenuItem
            key={index}
            title={item.title}
            link={item.link}
            onMenuItemSelect={onMenuItemSelect}
          />
        ))
      }
    </ul>
  );
}

export default Menu;
