import { Link } from 'react-router-dom';

import './NotFound.scss';

function NotFound() {
  return (
    <div className="not-found">
      <div className="abstract-container"></div>

      <h2>На жаль за вашим запитом нічого не знайдено</h2>

      <Link to="/">
        <button type="button">Перейти на головну</button>
      </Link>
    </div>
  );
}

export default NotFound;
