import { Link } from 'react-router-dom';

import {
  PRESENTATION_VIDEO,
} from '../../../constants';

import './About.scss';

function About() {
  return (
    <div className="about">
      <h2 className="title">
        Люблю те, що я роблю
      </h2>

      <div className="presentation">
        <video loop autoPlay="autoplay" id="about-video-bg" muted playsInline>
          <source src={PRESENTATION_VIDEO.src} type="video/mp4"/>
        </video>

        <a
          className="play"
          href={PRESENTATION_VIDEO.link}
          target="_blank"
          rel="noreferrer">

          <div className="btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fillRule="evenodd" fill="currentColor" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd"></path>
            </svg>
          </div>
        </a>
      </div>

      <div className="description">
        <Link to="/#contacts">
          <button type="button">Навчання</button>
        </Link>
      </div>
    </div>
  );
}

export default About;
