import scrollToHash from '../../../Services/ScrollToService';
import { HOME_BG_VIDEO_SRC } from '../../../constants';

import './Home.scss';

function Home() {
  const scrollDown = () => {
    scrollToHash('#about', 'smooth');
  };

  return (
    <div className="home">
      <video loop autoPlay="autoplay" id="cover-video-bg" muted playsInline>
        <source src={HOME_BG_VIDEO_SRC} type="video/mp4"/>
      </video>

      <div className="overlay"></div>

      <div className="content">
        <h1 className="first-title">Магія</h1>
        <h1 className="second-title">Макіяжу</h1>
      </div>

      <div
        onClick={scrollDown}
        className="scroll-down"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M315.3 363.3l-144 144C168.2 510.4 164.1 512 160 512s-8.188-1.562-11.31-4.688l-144-144c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L144 457.4V16C144 7.156 151.2 0 160 0s16 7.156 16 16v441.4l116.7-116.7c6.25-6.25 16.38-6.25 22.62 0S321.6 357.1 315.3 363.3z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
  );
}

export default Home;
