import { Link } from 'react-router-dom';

import './MenuItem.scss';

function MenuItem({ title, link, onMenuItemSelect }) {
  return (
    <li className="menu-item">
      <Link
        to={link}
        onClick={onMenuItemSelect}
        className="link"
      >
        {title}
      </Link>
    </li>
  );
}

export default MenuItem;
