import { createBrowserRouter } from 'react-router-dom';

import App from './App/App';
import Main from './App/Main';
import Album from './App/Album';
import ErrorPage from './App/ErrorPage';
import NotFound from './App/NotFound';
import { albumLoader } from './Loaders/albumLoader';
import { appDataLoader } from './Loaders/appDataLoader';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Main />,
        loader: appDataLoader,
      },
      {
        path: 'albums/:url',
        element: <Album />,
        loader: albumLoader,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
