function _getElementByHash(hash) {
  if (!hash) {
    return null;
  }

  const elementId = hash.slice(1);

  return document.getElementById(elementId);
}

function _scrollToElement(element, behavior = 'instant') {
  element.scrollIntoView({ behavior });
}

function scrollToHash(hash, behavior = 'instant') {
  const element = _getElementByHash(hash);

  if (!element) {
    return;
  }

  _scrollToElement(element, behavior)
}

export default scrollToHash;
