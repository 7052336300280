const albums = [
  {
    url: 'wedding-makeup',
    title: 'Наречені',
    description: 'Весільний макіяж та зачіска',
    coverImgSrc: '/static/media/wedding-makeup/wedding-makeup-cover.jpg',
    sources: [
      {
        src: '/static/media/wedding-makeup/01.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/2.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/3.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/4.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/5.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/6.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/7.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/8.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/9.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/10.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/11.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/12.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/13.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/14.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/15.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/16.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/17.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/18.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/19.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/20.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/21.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/22.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/23.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/24.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/25.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/26.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/27.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/28.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
      {
        src: '/static/media/wedding-makeup/29.jpg',
        alt: 'MakeUp Magic - Wedding MakeUp',
        title: 'MakeUp Magic - Wedding MakeUp',
      },
    ],
  },
  {
    url: 'beauty',
    title: 'Beauty',
    description: '',
    coverImgSrc: '/static/media/beauty/beauty-cover.jpg',
    sources: [
      {
        src: '/static/media/beauty/1.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/2.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/3.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/4.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },{
        src: '/static/media/beauty/5.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/6.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/7.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/8.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },{
        src: '/static/media/beauty/9.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/10.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/11.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/12.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },{
        src: '/static/media/beauty/13.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/14.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/15.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/16.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },{
        src: '/static/media/beauty/17.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/18.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/19.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/20.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },{
        src: '/static/media/beauty/21.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/22.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
      {
        src: '/static/media/beauty/23.jpg',
        alt: 'MakeUp Magic - Beauty MakeUp',
        title: 'MakeUp Magic - Beauty MakeUp',
      },
    ],
  },
  {
    url: 'students-works',
    title: 'Курси макіяжу',
    description: 'Роботи моїх учениць',
    coverImgSrc: '/static/media/students-works/students-works-cover.jpg',
    sources: [
      {
        src: '/static/media/students-works/1.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/2.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/3.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/4.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/5.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/6.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/7.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/8.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/9.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/10.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/11.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/12.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/13.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/14.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/15.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/16.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/17.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/18.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/19.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/20.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/21.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/22.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/23.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/24.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
      {
        src: '/static/media/students-works/25.jpg',
        alt: 'MakeUp Magic - Students Works',
        title: 'MakeUp Magic - Students Works',
      },
    ],
  },
];

export default albums;
