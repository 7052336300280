import albums from './Albums';

function getAlbumsList() {
  return albums.map(album => {
    const { url, title, description, coverImgSrc } = album;

    return { url, title, description, coverImgSrc }
  });
};

function getAlbumByUrl(url) {
  return albums.find(album => album.url === url);
};

const albumsProvider = { getAlbumsList, getAlbumByUrl };

export default albumsProvider;
