import { useLoaderData } from 'react-router-dom';

import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Price from './Price';
import Contacts from './Contacts';
import useScrollToHash from '../../Hooks/useScrollToHash';

import './Main.scss';

function Main() {
  useScrollToHash();

  const { albums } = useLoaderData();

  return (
    <div className="main">
      <div id="home">
        <Home />
      </div>

      <div id="about">
        <About />
      </div>

      <div id="portfolio">
        <Portfolio albums={albums} />
      </div>

      <div id="price">
        <Price />
      </div>

      <div id="contacts">
        <Contacts />
      </div>
    </div>
  );
}

export default Main;
