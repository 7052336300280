import albumsProvider from '../Providers/albumsProvider';
import pricesProvider from '../Providers/pricesProvider';
import socialsProvider from '../Providers/socialsProvider';
import contactsProvider from '../Providers/contactsProvider';

export async function appDataLoader() {
  const albums = albumsProvider.getAlbumsList();
  const prices = pricesProvider.getPrices();
  const socials = socialsProvider.getSocials();
  const contacts = contactsProvider.getContacts();

  return { albums, prices, socials, contacts };
}
